import { Component, inject, OnInit } from '@angular/core';
import { TranslationFacade } from 'shared/data-access/translation-settings';

@Component({
  selector: 'kiq-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private translationFacade = inject(TranslationFacade);

  ngOnInit() {
    this.translationFacade.getLastLanguageSettingsFromUser();
    this.emitAppLoadedEvent();
  }

  private emitAppLoadedEvent(): void {
    const event = new CustomEvent('app-loaded', { detail: { loaded: true } });
    window.dispatchEvent(event);
  }
}
