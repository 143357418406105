import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AdvertisementPlace } from '@kiq/shared/enums';
import { AdvertisementAsset } from '@kiq/shared/types';

export const AdvertisementActions = createActionGroup({
  source: 'Advertisement',
  events: {
    'init advertisement assets': emptyProps(),
    'init all advertisement assets types': emptyProps(),
    'get next advertisement': props<{ placement: AdvertisementPlace }>(),
    'preload multi advertisements start': props<{ urlsToPreload: string[] }>(),
    'preload multi advertisements success': props<{ urlsToPreload: string[] }>(),
    'preload multi advertisements failure': props<{ urlsToPreload: string[] }>(),
    'preload advertisement start': props<{ urlToPreload: string; placement: AdvertisementPlace }>(),
    'preload advertisement success': props<{ urlToPreload: string; placement: AdvertisementPlace }>(),
    'preload advertisement failure': props<{ urlToPreload: string; placement: AdvertisementPlace }>(),
    'set club advertisements start': props<{ clubName: string | null }>(),
    'set club advertisements success': props<{ advertisementAssets: AdvertisementAsset[] }>(),
  },
});
