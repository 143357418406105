export enum UserTrophyType {
  TIC_TAC_TOE_RANKING_WEEK = 'ticTacToeRankingWeek',
  TIC_TAC_TOE_RANKING_MONTH = 'ticTacToeRankingMonth',
  TIC_TAC_TOE_RANKING_YEAR = 'ticTacToeRankingYear',
  TIC_TAC_TOE_ONLINE_VICTORIES = 'ticTacToeOnlineVictories',
  TIC_TAC_TOE_ONLINE_MATCHES = 'ticTacToeOnlineMatches',
  TIC_TAC_TOE_ONLINE_SOLVED_PLAYERS = 'ticTacToeOnlineSolvedPlayers',
  GRID_MASTER = 'ticTacToeSinglePlayerSolvedGridsWithoutHints',
  GRID_SPECIALIST = 'ticTacToeSinglePlayerSolvedGridsWithHints',
  TIC_TAC_TOE_ONLINE_GAME_SERIES = 'gameSeries',
  TOP_SCORER_RANKING_WEEK = 'topScorerRankingWeek',
  TOP_SCORER_RANKING_MONTH = 'topScorerRankingMonth',
  TOP_SCORER_RANKING_YEAR = 'topScorerRankingYear',
  TOPSCORER_SCORE_AMATEUR = 'topscorerScoreAmateur',
  TOPSCORER_SCORE_PRO = 'topscorerScorePro',
  TOPSCORER_SCORE_LEGENDARY = 'topscorerScoreLegendary',
  PLAYER_GUESSING_RANKING_WEEK = 'playerGuessingRankingWeek',
  PLAYER_GUESSING_RANKING_MONTH = 'playerGuessingRankingMonth',
  PLAYER_GUESSING_RANKING_YEAR = 'playerGuessingRankingYear',
  PLAYER_GUESSING_SOLVED_PLAYERS_AMATEUR = 'playerGuessingSolvedPlayersAmateur',
  PLAYER_GUESSING_SOLVED_PLAYERS_PRO = 'playerGuessingSolvedPlayersPro',
  PLAYER_GUESSING_SOLVED_PLAYERS_LEGENDARY = 'playerGuessingSolvedPlayersLegendary',
  WELCOME = 'welcome',
  EMAIL_VERIFICATION = 'emailVerification',
  PLAYER_INVITATIONS = 'playerInvitations',
  FOUNDER = 'kikkzFounder',
  QUIZDUEL_RANKING_WEEK = 'quizduelRankingWeek',
  QUIZDUEL_RANKING_MONTH = 'quizduelRankingMonth',
  QUIZDUEL_RANKING_YEAR = 'quizduelRankingYear',
  QUIZDUEL_MATCH_WINNER = 'quizduelMatchWinner',
  QUIZDUEL_PERFECT_MATCH = 'quizduelPerfectMatch',
  ALL_GAMES_ONLINE_OPPONENTS = 'allGamesOnlineOpponents',
}
