import { animate, animateChild, keyframes, query, style, transition, trigger } from '@angular/animations';

export const sideSlideOut = trigger('slideSideChild', [
  transition(':leave', [
    style({ opacity: 1, transform: 'translateX(0)' }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' })),
  ]),
]);

export const slideUpChild = trigger('slideUpChild', [
  transition(':enter', [
    style({ transform: 'translateY(100%)' }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ transform: 'translateY(0)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0)' }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ transform: 'translateY(100%)' })),
  ]),
]);

export const slideInAndOut = trigger('slideInAndOut', [
  transition(':enter', [
    style({ transform: 'translateY(100%)' }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ transform: 'translateY(0)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0)' }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ transform: 'translateY(100%)' })),
  ]),
]);

export const slideInChild = trigger('slideInChild', [
  transition(':enter', [
    style({ transform: 'translateY(-25%)', opacity: 0 }), //apply default styles before animation starts
    animate('500ms 300ms ease-in-out', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
]);

export const slideInFromLeft = trigger('slideInFromLeft', [
  transition(':enter', [
    style({ transform: 'translateX(-150%)' }), //apply default styles before animation starts
    animate('500ms 300ms ease-in-out', style({ transform: 'translateX(0)' })),
  ]),
]);

export const slideInFromRight = trigger('slideInFromRight', [
  transition(':enter', [
    query('@*', animateChild(), { optional: true }),
    style({ transform: 'translateX(150%)' }), //apply default styles before animation starts
    animate('500ms 300ms ease-in-out', style({ transform: 'translateX(0)' })),
  ]),
]);

export const slideInFromRightWithBounce = trigger('slideInFromRightWithBounce', [
  transition(':enter', [
    query('@*', animateChild(), { optional: true }),
    style({ transform: 'translateX(150%)' }), // Initial position off-screen to the right
    animate(
      '2000ms 500ms ease-in-out', // Smooth easing for overall motion
      keyframes([
        style({ transform: 'translateX(-10px)', easing: 'ease-out', offset: 0.5 }), // Slow down to the further position
        style({ transform: 'translateX(-10px)', offset: 0.55 }), // Stay shortly at that position
        style({ transform: 'translateX(2px)', easing: 'ease-out', offset: 0.85 }), // Slow down to next position
        style({ transform: 'translateX(2px)', offset: 0.9 }), // Stay shortly at that position
        style({ transform: 'translateX(0)', easing: 'ease-out', offset: 1.0 }), // Settles softly in the final position
      ]),
    ),
  ]),
]);

export const slideInFromLeftWithBounce = trigger('slideInFromLeftWithBounce', [
  transition(':enter', [
    query('@*', animateChild(), { optional: true }),
    style({ transform: 'translateX(-150%)' }), // Initial position off-screen to the left
    animate(
      '2000ms 500ms ease-in-out',
      keyframes([
        style({ transform: 'translateX(10px)', easing: 'ease-out', offset: 0.5 }), // Slow down to the further position
        style({ transform: 'translateX(10px)', offset: 0.55 }), // Stay shortly at that position
        style({ transform: 'translateX(-2px)', easing: 'ease-out', offset: 0.85 }), // Slow down to next position
        style({ transform: 'translateX(-2px)', offset: 0.9 }), // Stay shortly at that position
        style({ transform: 'translateX(0)', easing: 'ease-out', offset: 1 }), // Settles softly in the final position
      ]),
    ),
  ]),
]);

export const slideInFromRightLate = trigger('slideInFromRightLate', [
  transition(':enter', [
    query('@*', animateChild(), { optional: true }),
    style({ transform: 'translateX(150%)' }), //apply default styles before animation starts
    animate('500ms 1000ms ease-in-out', style({ transform: 'translateX(0)' })),
  ]),
]);

export const slideOutToLeft = trigger('slideOutToLeft', [
  transition(':leave', [
    query('@*', animateChild(), { optional: true }),
    style({ transform: 'translateX(0)' }), //apply default styles before animation starts
    animate('500ms ease-in-out', style({ transform: 'translateX(-150%)' })),
  ]),
]);

export const slideOutToLeftLate = trigger('slideOutToLeftLate', [
  transition(':leave', [
    query('@*', animateChild(), { optional: true }),
    style({ transform: 'translateX(0)' }), //apply default styles before animation starts
    animate('500ms 1000ms ease-in-out', style({ transform: 'translateX(-150%)' })),
  ]),
]);

export const slideInAnswers = trigger('slideInAnswers', [
  transition(':enter', [
    style({ transform: 'translateY(-200%)' }), //apply default styles before animation starts
    animate('500ms 300ms', style({ transform: 'translateY(0)' })),
  ]),
]);

export const slideInScoreboard = trigger('slideInScoreboard', [
  transition(':enter', [
    style({ transform: 'translateY(-150%)' }), //apply default styles before animation starts
    animate('300ms', style({ transform: 'translateY(0)' })),
  ]),
]);

export const slideInRankings = trigger('slideInRankings', [
  transition(':enter', [
    style({ transform: 'translateY(-20px)', opacity: 0 }), //apply default styles before animation starts
    animate('500ms', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
]);

export const fadeInInitialGameScreen = trigger('fadeInInitialGameScreen', [
  transition(':enter', [
    style({ opacity: 0 }), //apply default styles before animation starts
    animate('300ms', style({ opacity: 1 })),
  ]),
]);

export const fadeOutInitialGameScreen = trigger('fadeOutInitialGameScreen', [
  transition(':leave', [
    style({ opacity: 1 }), //apply default styles before animation starts
    animate('300ms', style({ opacity: 0 })),
  ]),
]);

export const fadeInGame = trigger('fadeInGame', [
  transition(':enter', [
    style({ opacity: 0 }), //apply default styles before animation starts
    animate('300ms', style({ opacity: 1 })),
  ]),
]);

export const fadeInSlow = trigger('fadeInSlow', [
  transition(':enter', [
    style({ opacity: 0 }), //apply default styles before animation starts
    animate('500ms 300ms', style({ opacity: 1 })),
  ]),
]);

export const fadeOutGameBoard = trigger('fadeOutGameBoard', [
  transition(':leave', [
    style({ opacity: 1 }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ opacity: 0 })),
  ]),
]);

export const fadeOutCategoryCard = trigger('fadeOutCategoryCard', [
  transition(':leave', [
    style({ opacity: 1 }), //apply default styles before animation starts
    animate('800ms ease-in-out', style({ opacity: 0 })),
  ]),
]);

export const slideInFromBottom = trigger('slideInFromBottom', [
  transition(':enter', [
    style({ transform: 'translateY(25%)', opacity: 0 }), //apply default styles before animation starts
    animate('500ms 1000ms ease-in-out', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
]);

export const slideInFromBottomWithoutDelay = trigger('slideInFromBottomWithoutDelay', [
  transition(':enter', [
    style({ transform: 'translateY(25%)', opacity: 0 }), //apply default styles before animation starts
    animate('1000ms ease-in-out', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
]);

export const slideInFromBottomWithoutDelayQuick = trigger('slideInFromBottomWithoutDelayQuick', [
  transition(':enter', [
    style({ transform: 'translateY(25%)', opacity: 0 }), //apply default styles before animation starts
    animate('500ms ease-in-out', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
]);

export const slideInFromBottomWith2SecondsDelay = trigger('slideInFromBottomWith2SecondsDelay', [
  transition(':enter', [
    style({ transform: 'translateY(25%)', opacity: 0 }), //apply default styles before animation starts
    animate('500ms 3000ms ease-in-out', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
]);

export const slideInFromTop = trigger('slideInFromTop', [
  transition(':enter', [
    style({ transform: 'translateY(-25%)', opacity: 0 }), //apply default styles before animation starts
    animate('600ms 600ms ease-in-out', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
]);

export const slideOutToTop = trigger('slideOutToTop', [
  transition(':leave', [
    style({ transform: 'translateY(0)', opacity: 1 }), //apply default styles before animation starts
    animate('500ms ease-in-out', style({ transform: 'translateY(-150%)', opacity: 0 })),
  ]),
]);

export const navSlideInFromBottom = trigger('navSlideInFromBottom', [
  transition(':enter', [
    style({ position: 'absolute', opacity: 0 }), //apply default styles before animation starts
    animate('0ms 600ms ease-in-out', style({ position: 'static', transform: 'translateY(50%)', opacity: 0 })),
    animate('600ms ease-in-out', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
]);

export const slideOutToBottom = trigger('slideOutToBottom', [
  transition(':leave', [
    style({ transform: 'translateY(0)', opacity: 1 }), //apply default styles before animation starts
    animate('600ms ease-in-out', style({ transform: 'translateY(50%)', opacity: 0, position: 'absolute' })),
  ]),
]);

export const focusOnSelection = trigger('focusOnSelection', [
  transition(':leave', [
    style({ opacity: 1 }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ opacity: 1 })),
  ]),
]);

export const pointDisappearing = trigger('pointDisappearing', [
  transition(':leave', [
    style({ opacity: 1 }), //apply default styles before animation starts
    animate('1000ms ease-in-out', style({ opacity: 0 })),
  ]),
]);
