// Auto-generated file. Do not edit manually.

import { AdvertisementPlace, AdvertisementType, ViewSize } from '@kiq/shared/enums';
import { AdvertisementAsset } from '@kiq/shared/types';

export const generalAdvertisementAssets: Map<AdvertisementPlace, AdvertisementAsset[]> = new Map([
  
      ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-0",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3648061&v=77004&q=486312&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3648061&v=77004&q=486312&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3648061&v=77004&q=486312&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3648061&v=77004&q=486312&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3648061&v=77004&q=486312&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3648061&v=77004&q=486312&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-gamesOverview-1",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3756084&v=77004&q=487610&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3756084&v=77004&q=487610&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3756084&v=77004&q=487610&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3756084&v=77004&q=487610&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3756084&v=77004&q=487610&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3756084&v=77004&q=487610&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-gamesOverview-2",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3804492&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3804492&v=10073&q=383605&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3804492&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3804492&v=10073&q=383605&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3804492&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3804492&v=10073&q=383605&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "ODLO-gamesOverview-3",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3816639&v=14586&q=507372&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3816639&v=14586&q=507372&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3816639&v=14586&q=507372&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3816639&v=14586&q=507372&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3816639&v=14586&q=507372&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3816639&v=14586&q=507372&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Rose-Bikes-gamesOverview-4",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3718309&v=11768&q=434131&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3718309&v=11768&q=434131&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3718309&v=11768&q=434131&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3718309&v=11768&q=434131&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3718309&v=11768&q=434131&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3718309&v=11768&q=434131&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-gamesOverview-5",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3694601&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694601&v=10073&q=383605&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3694601&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694601&v=10073&q=383605&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3694601&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694601&v=10073&q=383605&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Rose-Bikes-gamesOverview-6",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3694529&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694529&v=11768&q=469428&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3694529&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694529&v=11768&q=469428&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3694529&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694529&v=11768&q=469428&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-gamesOverview-7",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3629786&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629786&v=10073&q=383605&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3629786&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629786&v=10073&q=383605&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3629786&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629786&v=10073&q=383605&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Uhlsport-gamesOverview-8",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3665576&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665576&v=65462&q=475597&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3665576&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665576&v=65462&q=475597&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3665576&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665576&v=65462&q=475597&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Rose-Bikes-gamesOverview-9",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3638031&v=11768&q=484896&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3638031&v=11768&q=484896&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3638031&v=11768&q=484896&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3638031&v=11768&q=484896&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3638031&v=11768&q=484896&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3638031&v=11768&q=484896&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-gamesOverview-10",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3605737&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3605737&v=10073&q=383605&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3605737&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3605737&v=10073&q=383605&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3605737&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3605737&v=10073&q=383605&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-gamesOverview-11",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3567706&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3567706&v=10073&q=383605&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3567706&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3567706&v=10073&q=383605&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3567706&v=10073&q=383605&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3567706&v=10073&q=383605&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Uhlsport-gamesOverview-12",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3493757&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3493757&v=65462&q=475597&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3493757&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3493757&v=65462&q=475597&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3493757&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3493757&v=65462&q=475597&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Myprotein-gamesOverview-13",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3203104&v=10700&q=329083&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3203104&v=10700&q=329083&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3203104&v=10700&q=329083&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3203104&v=10700&q=329083&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3203104&v=10700&q=329083&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3203104&v=10700&q=329083&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Sport-Thieme-gamesOverview-14",
    "placement": "gamesOverview",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2398309&v=11417&q=332767&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2398309&v=11417&q=332767&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2398309&v=11417&q=332767&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2398309&v=11417&q=332767&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2398309&v=11417&q=332767&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2398309&v=11417&q=332767&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
    ,

      ["ranking" as AdvertisementPlace, [
  {
    "clubName": null,
    "id": "JD-Sports-ranking-15",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3567711&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3567711&v=10073&q=383620&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3567711&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3567711&v=10073&q=383620&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3567711&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3567711&v=10073&q=383620&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-ranking-16",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3605743&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3605743&v=10073&q=383620&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3605743&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3605743&v=10073&q=383620&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3605743&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3605743&v=10073&q=383620&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-ranking-17",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3273003&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3273003&v=10073&q=383620&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3273003&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3273003&v=10073&q=383620&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3273003&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3273003&v=10073&q=383620&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Myprotein-ranking-18",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3203114&v=10700&q=329083&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3203114&v=10700&q=329083&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3203114&v=10700&q=329083&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3203114&v=10700&q=329083&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3203114&v=10700&q=329083&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3203114&v=10700&q=329083&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-ranking-19",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3629802&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629802&v=10073&q=383620&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3629802&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629802&v=10073&q=383620&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3629802&v=10073&q=383620&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629802&v=10073&q=383620&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Uhlsport-ranking-20",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3665574&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665574&v=65462&q=475597&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3665574&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665574&v=65462&q=475597&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3665574&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665574&v=65462&q=475597&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Uhlsport-ranking-21",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3620416&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3620416&v=65462&q=475597&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3620416&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3620416&v=65462&q=475597&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3620416&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3620416&v=65462&q=475597&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Uhlsport-ranking-22",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3620415&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3620415&v=65462&q=475597&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3620415&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3620415&v=65462&q=475597&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3620415&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3620415&v=65462&q=475597&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Uhlsport-ranking-23",
    "placement": "ranking",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3620410&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3620410&v=65462&q=475597&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3620410&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3620410&v=65462&q=475597&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3620410&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3620410&v=65462&q=475597&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
    ,

      ["inGame" as AdvertisementPlace, [
  {
    "clubName": null,
    "id": "Rose-Bikes-inGame-24",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3815263&v=11768&q=507618&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815263&v=11768&q=507618&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3815263&v=11768&q=507618&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815263&v=11768&q=507618&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3815263&v=11768&q=507618&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815263&v=11768&q=507618&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Rose-Bikes-inGame-25",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3721332&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3721332&v=11768&q=469428&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3721332&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3721332&v=11768&q=469428&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3721332&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3721332&v=11768&q=469428&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-inGame-26",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3694605&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694605&v=10073&q=383606&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3694605&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694605&v=10073&q=383606&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3694605&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694605&v=10073&q=383606&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Rose-Bikes-inGame-27",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3694531&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694531&v=11768&q=469428&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3694531&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694531&v=11768&q=469428&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3694531&v=11768&q=469428&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3694531&v=11768&q=469428&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-inGame-28",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3629787&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629787&v=10073&q=383606&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3629787&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629787&v=10073&q=383606&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3629787&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3629787&v=10073&q=383606&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Uhlsport-inGame-29",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3665567&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665567&v=65462&q=475597&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3665567&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665567&v=65462&q=475597&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3665567&v=65462&q=475597&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3665567&v=65462&q=475597&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-inGame-30",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3605739&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3605739&v=10073&q=383606&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3605739&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3605739&v=10073&q=383606&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3605739&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3605739&v=10073&q=383606&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-inGame-31",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3567707&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3567707&v=10073&q=383606&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3567707&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3567707&v=10073&q=383606&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3567707&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3567707&v=10073&q=383606&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "JD-Sports-inGame-32",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3272994&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3272994&v=10073&q=383606&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3272994&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3272994&v=10073&q=383606&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3272994&v=10073&q=383606&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3272994&v=10073&q=383606&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Myprotein-inGame-33",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3203106&v=10700&q=329083&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3203106&v=10700&q=329083&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3203106&v=10700&q=329083&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3203106&v=10700&q=329083&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3203106&v=10700&q=329083&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3203106&v=10700&q=329083&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Sport-Thieme-inGame-34",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2398311&v=11417&q=332767&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2398311&v=11417&q=332767&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2398311&v=11417&q=332767&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2398311&v=11417&q=332767&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2398311&v=11417&q=332767&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2398311&v=11417&q=332767&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Sport-Thieme-inGame-35",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2379401&v=11417&q=332767&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2379401&v=11417&q=332767&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2379401&v=11417&q=332767&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2379401&v=11417&q=332767&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2379401&v=11417&q=332767&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2379401&v=11417&q=332767&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-36",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3648069&v=77004&q=486312&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3648069&v=77004&q=486312&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3648069&v=77004&q=486312&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3648069&v=77004&q=486312&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3648069&v=77004&q=486312&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3648069&v=77004&q=486312&r=1799318"
      }
    }
  },
  {
    "clubName": null,
    "id": "Adidas-inGame-37",
    "placement": "inGame",
    "type": "general",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3756078&v=77004&q=487610&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3756078&v=77004&q=487610&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3756078&v=77004&q=487610&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3756078&v=77004&q=487610&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3756078&v=77004&q=487610&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3756078&v=77004&q=487610&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
    
]);

export const clubAdvertisementAssets: Map<string, Map<AdvertisementPlace, AdvertisementAsset[]>> = new Map([
  
      ["Werder Bremen", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-gamesOverview-0",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3815889&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815889&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3815889&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815889&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3815889&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815889&v=21339&q=401405&r=1799318"
      }
    }
  },
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-gamesOverview-1",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3815886&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815886&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3815886&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815886&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3815886&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815886&v=21339&q=401405&r=1799318"
      }
    }
  },
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-gamesOverview-11",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2762553&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762553&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2762553&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762553&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2762553&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762553&v=21339&q=401405&r=1799318"
      }
    }
  },
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-gamesOverview-13",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3228469&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3228469&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3228469&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3228469&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3228469&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3228469&v=21339&q=401405&r=1799318"
      }
    }
  },
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-gamesOverview-14",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2762551&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762551&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2762551&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762551&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2762551&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762551&v=21339&q=401405&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["ranking" as AdvertisementPlace, [
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-ranking-20",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3815926&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815926&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3815926&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815926&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3815926&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3815926&v=21339&q=401405&r=1799318"
      }
    }
  },
  {
    "clubName": "Werder-Bremen",
    "id": "Werder-Bremen-ranking-27",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2762563&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762563&v=21339&q=401405&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2762563&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762563&v=21339&q=401405&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2762563&v=21339&q=401405&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2762563&v=21339&q=401405&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["Jahn Regensburg", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "Jahn-Regensburg",
    "id": "Jahn-Regensburg-gamesOverview-2",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2833000&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2833000&v=21970&q=407476&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2833000&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2833000&v=21970&q=407476&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2833000&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2833000&v=21970&q=407476&r=1799318"
      }
    }
  },
  {
    "clubName": "Jahn-Regensburg",
    "id": "Jahn-Regensburg-gamesOverview-4",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2934058&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2934058&v=21970&q=407476&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2934058&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2934058&v=21970&q=407476&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2934058&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2934058&v=21970&q=407476&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["ranking" as AdvertisementPlace, [
  {
    "clubName": "Jahn-Regensburg",
    "id": "Jahn-Regensburg-ranking-21",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2836702&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2836702&v=21970&q=407476&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2836702&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2836702&v=21970&q=407476&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2836702&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2836702&v=21970&q=407476&r=1799318"
      }
    }
  },
  {
    "clubName": "Jahn-Regensburg",
    "id": "Jahn-Regensburg-ranking-23",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3144187&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3144187&v=21970&q=407476&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3144187&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3144187&v=21970&q=407476&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3144187&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3144187&v=21970&q=407476&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["inGame" as AdvertisementPlace, [
  {
    "clubName": "Jahn-Regensburg",
    "id": "Jahn-Regensburg-inGame-30",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2932382&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2932382&v=21970&q=407476&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2932382&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2932382&v=21970&q=407476&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2932382&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2932382&v=21970&q=407476&r=1799318"
      }
    }
  },
  {
    "clubName": "Jahn-Regensburg",
    "id": "Jahn-Regensburg-inGame-31",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3074870&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3074870&v=21970&q=407476&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3074870&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3074870&v=21970&q=407476&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3074870&v=21970&q=407476&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3074870&v=21970&q=407476&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["Darmstadt 98", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "Darmstadt-98",
    "id": "Darmstadt-98-gamesOverview-3",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3199934&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3199934&v=20984&q=400236&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3199934&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3199934&v=20984&q=400236&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3199934&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3199934&v=20984&q=400236&r=1799318"
      }
    }
  },
  {
    "clubName": "Darmstadt-98",
    "id": "Darmstadt-98-gamesOverview-7",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2808882&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2808882&v=20984&q=400236&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2808882&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2808882&v=20984&q=400236&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2808882&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2808882&v=20984&q=400236&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["ranking" as AdvertisementPlace, [
  {
    "clubName": "Darmstadt-98",
    "id": "Darmstadt-98-ranking-22",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2783264&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2783264&v=20984&q=400236&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2783264&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2783264&v=20984&q=400236&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2783264&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2783264&v=20984&q=400236&r=1799318"
      }
    }
  },
  {
    "clubName": "Darmstadt-98",
    "id": "Darmstadt-98-ranking-26",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3127051&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3127051&v=20984&q=400236&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3127051&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3127051&v=20984&q=400236&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3127051&v=20984&q=400236&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3127051&v=20984&q=400236&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["Union Berlin", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "Union-Berlin",
    "id": "Union-Berlin-gamesOverview-5",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3349420&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349420&v=41734&q=457527&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3349420&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349420&v=41734&q=457527&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3349420&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349420&v=41734&q=457527&r=1799318"
      }
    }
  },
  {
    "clubName": "Union-Berlin",
    "id": "Union-Berlin-gamesOverview-6",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3325963&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325963&v=41734&q=457527&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3325963&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325963&v=41734&q=457527&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3325963&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325963&v=41734&q=457527&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["ranking" as AdvertisementPlace, [
  {
    "clubName": "Union-Berlin",
    "id": "Union-Berlin-ranking-24",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3349434&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349434&v=41734&q=457527&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3349434&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349434&v=41734&q=457527&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3349434&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349434&v=41734&q=457527&r=1799318"
      }
    }
  },
  {
    "clubName": "Union-Berlin",
    "id": "Union-Berlin-ranking-25",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3325989&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325989&v=41734&q=457527&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3325989&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325989&v=41734&q=457527&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3325989&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325989&v=41734&q=457527&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["inGame" as AdvertisementPlace, [
  {
    "clubName": "Union-Berlin",
    "id": "Union-Berlin-inGame-32",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3349421&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349421&v=41734&q=457527&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3349421&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349421&v=41734&q=457527&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3349421&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3349421&v=41734&q=457527&r=1799318"
      }
    }
  },
  {
    "clubName": "Union-Berlin",
    "id": "Union-Berlin-inGame-33",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3325964&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325964&v=41734&q=457527&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3325964&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325964&v=41734&q=457527&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3325964&v=41734&q=457527&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3325964&v=41734&q=457527&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["Schalke 04", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-gamesOverview-8",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2420881&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420881&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2420881&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420881&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2420881&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420881&v=13879&q=366594&r=1799318"
      }
    }
  },
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-gamesOverview-10",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2420875&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420875&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2420875&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420875&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2420875&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420875&v=13879&q=366594&r=1799318"
      }
    }
  },
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-gamesOverview-12",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2420861&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420861&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2420861&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420861&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2420861&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420861&v=13879&q=366594&r=1799318"
      }
    }
  },
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-gamesOverview-15",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2420892&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420892&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2420892&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420892&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2420892&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420892&v=13879&q=366594&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["inGame" as AdvertisementPlace, [
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-inGame-36",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2420874&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420874&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2420874&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420874&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2420874&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420874&v=13879&q=366594&r=1799318"
      }
    }
  },
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-inGame-37",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2765458&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2765458&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2765458&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2765458&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2765458&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2765458&v=13879&q=366594&r=1799318"
      }
    }
  },
  {
    "clubName": "Schalke-04",
    "id": "Schalke-04-inGame-38",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2420860&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420860&v=13879&q=366594&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2420860&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420860&v=13879&q=366594&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2420860&v=13879&q=366594&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2420860&v=13879&q=366594&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["VfL Bochum", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "VfL-Bochum",
    "id": "VfL-Bochum-gamesOverview-9",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2806145&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2806145&v=19956&q=393457&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2806145&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2806145&v=19956&q=393457&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2806145&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2806145&v=19956&q=393457&r=1799318"
      }
    }
  },
  {
    "clubName": "VfL-Bochum",
    "id": "VfL-Bochum-gamesOverview-16",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2714530&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2714530&v=19956&q=393457&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2714530&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2714530&v=19956&q=393457&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2714530&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2714530&v=19956&q=393457&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["ranking" as AdvertisementPlace, [
  {
    "clubName": "VfL-Bochum",
    "id": "VfL-Bochum-ranking-28",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2714531&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2714531&v=19956&q=393457&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2714531&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2714531&v=19956&q=393457&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2714531&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2714531&v=19956&q=393457&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["inGame" as AdvertisementPlace, [
  {
    "clubName": "VfL-Bochum",
    "id": "VfL-Bochum-inGame-34",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2691727&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2691727&v=19956&q=393457&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2691727&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2691727&v=19956&q=393457&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2691727&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2691727&v=19956&q=393457&r=1799318"
      }
    }
  },
  {
    "clubName": "VfL-Bochum",
    "id": "VfL-Bochum-inGame-35",
    "placement": "inGame",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3208599&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3208599&v=19956&q=393457&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3208599&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3208599&v=19956&q=393457&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3208599&v=19956&q=393457&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3208599&v=19956&q=393457&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["Bayer Leverkusen", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "Bayer-Leverkusen",
    "id": "Bayer-Leverkusen-gamesOverview-17",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3479016&v=66252&q=474102&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3479016&v=66252&q=474102&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3479016&v=66252&q=474102&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3479016&v=66252&q=474102&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3479016&v=66252&q=474102&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3479016&v=66252&q=474102&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        ,

          ["ranking" as AdvertisementPlace, [
  {
    "clubName": "Bayer-Leverkusen",
    "id": "Bayer-Leverkusen-ranking-29",
    "placement": "ranking",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=3574079&v=66252&q=483928&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3574079&v=66252&q=483928&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=3574079&v=66252&q=483928&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3574079&v=66252&q=483928&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=3574079&v=66252&q=483928&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=3574079&v=66252&q=483928&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    ,

      ["FC Bayern München", new Map([
        
          ["gamesOverview" as AdvertisementPlace, [
  {
    "clubName": "FC-Bayern-München",
    "id": "FC-Bayern-München-gamesOverview-18",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2508146&v=14076&q=375797&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2508146&v=14076&q=375797&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2508146&v=14076&q=375797&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2508146&v=14076&q=375797&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2508146&v=14076&q=375797&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2508146&v=14076&q=375797&r=1799318"
      }
    }
  },
  {
    "clubName": "FC-Bayern-München",
    "id": "FC-Bayern-München-gamesOverview-19",
    "placement": "gamesOverview",
    "type": "club",
    "viewports": {
      "mobile": {
        "link": "https://www.awin1.com/cread.php?s=2508164&v=14076&q=375242&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2508164&v=14076&q=375242&r=1799318"
      },
      "tablet": {
        "link": "https://www.awin1.com/cread.php?s=2508164&v=14076&q=375242&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2508164&v=14076&q=375242&r=1799318"
      },
      "desktop": {
        "link": "https://www.awin1.com/cread.php?s=2508164&v=14076&q=375242&r=1799318",
        "imageUrl": "https://www.awin1.com/cshow.php?s=2508164&v=14076&q=375242&r=1799318"
      }
    }
  }
] as AdvertisementAsset[]]
        
      ])]
    
]);
