import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { AdvertisementPlace, ViewSize } from '@kiq/shared/enums';
import { advertisementData } from '@kiq/client/game-data';
import { AdvertisementAsset } from '@kiq/shared/types';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AdvertisementService {
  private readonly renderer: Renderer2;
  private readonly rendererFactory = inject(RendererFactory2);
  private readonly document = inject(DOCUMENT);

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  private static getHeader(jwtToken?: string, withoutContentType = false): { headers: HttpHeaders } {
    const headers = {
      headers: new HttpHeaders({}),
    };

    if (jwtToken) {
      headers.headers = headers.headers.append('Authorization', `Bearer ${jwtToken}`);
    }
    if (!withoutContentType) {
      headers.headers = headers.headers.append('Content-Type', 'application/json');
    }
    return headers;
  }

  preloadAdvertisementAsset$(urlToPreload: string): Observable<string> {
    return new Observable((observer) => {
      const dummyImgElement = this.renderer.createElement('img');

      dummyImgElement.onload = () => {
        observer.next(urlToPreload);
        observer.complete();
      };

      dummyImgElement.onerror = () => {
        observer.error(`Failed to preload: ${urlToPreload}`);
      };

      this.renderer.setAttribute(dummyImgElement, 'src', urlToPreload);

      this.renderer.setStyle(dummyImgElement, 'display', 'none');
      this.renderer.appendChild(this.document.body, dummyImgElement);

      return () => {
        // cleanup called when the subscription is unsubscribed / completed
        this.renderer.removeChild(this.document.body, dummyImgElement);
      };
    });
  }
}
