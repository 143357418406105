import { GamePlayerGuessingHintType } from '@kiq/shared/enums';
import { FootballTeam } from '@kiq/shared/core';

export type GamePlayerGuessingHint = {
  value: string;
  type: GamePlayerGuessingHintType;
  team: FootballTeam | null;
  country: string | null;
  countrySubdivisionCode: string | null;
};
