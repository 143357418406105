import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { ChallengersLoaderComponent } from './challengers-loader/challengers-loader.component';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  imports: [CommonModule, TranslocoDirective],
  declarations: [LoaderComponent, ChallengersLoaderComponent],
  exports: [LoaderComponent, ChallengersLoaderComponent],
})
export class ClientUiLoaderModule {}
