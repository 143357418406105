import { InjectionToken } from '@angular/core';

export const BACKEND_TOKEN = new InjectionToken('BACKEND');
export const TTT_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('TTT_FEATURE_FLAG_TOKEN');
export const WII_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('WII_FEATURE_FLAG_TOKEN');
export const TOPSCORER_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('TOPSCORER_FEATURE_FLAG_TOKEN');
export const MIXED_ZONE_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('MIXED_ZONE_FEATURE_FLAG_TOKEN');
export const FAKE_OR_FACT_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('FAKE_OR_FACT_FEATURE_FLAG_TOKEN');
export const NATIONS_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('NATIONS_FEATURE_FLAG_TOKEN');
export const IN_GAME_AD_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('IN_GAME_AD_FEATURE_FLAG_TOKEN');
export const RANKING_AD_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('RANKING_AD_FEATURE_FLAG_TOKEN');
export const GAMES_OVERVIEW_AD_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('GAMES_OVERVIEW_AD_FEATURE_FLAG_TOKEN');
export const BULI_LICENSE_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('BULI_LICENSE_FEATURE_FLAG');
export const TROPHIES_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('TROPHIES_FEATURE_FLAG_TOKEN');
export const ASYNC_GAMES_TOKEN = new InjectionToken<boolean>('ASYNC_GAMES_FEATURE_FLAG_TOKEN');
export const LANGUAGE_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('LANGUAGE_FEATURE_FLAG_TOKEN');
export const NOTIFICATION_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('NOTIFICATION_FEATURE_FLAG_TOKEN');
export const NOTIFICATIONS_DEBUG_INFORMATION_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>(
  'NOTIFICATIONS_DEBUG_INFORMATION_FEATURE_FLAG_TOKEN',
);
export const KIKKZ_APP_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('KIKKZ_APP_FEATURE_FLAG_TOKEN');
export const DATA_SINCE_1990_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('DATA_SINCE_1990_FEATURE_FLAG_TOKEN');
export const TIMER_BAR_IN_PLAYER_SEARCH_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>(
  'TIMER_BAR_IN_PLAYER_SEARCH_FEATURE_FLAG_TOKEN',
);
export const VS_PRO_FEATURE_FLAG_TOKEN = new InjectionToken<boolean>('VS_PRO_FEATURE_FLAG_TOKEN');
