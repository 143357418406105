export const advertisementData: Array<any> = [
  {
    id: '1',
    imageUrl: 'assets/img/advertisement-placeholder.svg',
  },
  {
    id: '2',
    imageUrl: 'assets/img/itsquare-logo-bg-white.svg',
  },
  {
    id: '3',
    imageUrl: 'assets/img/kikkz-logo.svg',
  },
];
