import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectAlreadyLoadedAssetImgUrls,
  selectCurrentGamesOverviewAdvertisementAsset,
  selectCurrentInGameAdvertisementAsset,
  selectCurrentRankingAdvertisementAsset,
  selectGamesOverviewLoading,
  selectInGameLoading,
  selectRankingLoading,
} from './advertisement.reducer';
import { AdvertisementActions } from './advertisement.actions';
import { AdvertisementPlace } from '@kiq/shared/enums';
import { combineLatest, map, Observable } from 'rxjs';
import { AdvertisementAsset, AdvertisementViewportAsset } from '@kiq/shared/types';
import { ConfigFacade } from '@kiq/client/data-access/config';
import { NativeAppsService } from '@kiq/shared/data-access/native-apps';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable()
export class AdvertisementFacade {
  private readonly store = inject(Store);
  private readonly configFacade = inject(ConfigFacade);
  private readonly nativeAppsService = inject(NativeAppsService);

  readonly viewSize$ = toObservable(this.configFacade.viewSize);
  readonly currentRankingAdvertisementAsset$: Observable<AdvertisementAsset | null> = this.store.select(
    selectCurrentRankingAdvertisementAsset,
  );
  readonly currentInGameAdvertisementAsset$: Observable<AdvertisementAsset | null> = this.store.select(
    selectCurrentInGameAdvertisementAsset,
  );
  readonly currentGamesOverviewAdvertisementAsset$: Observable<AdvertisementAsset | null> = this.store.select(
    selectCurrentGamesOverviewAdvertisementAsset,
  );
  readonly alreadyLoadedAssetImgUrls$: Observable<Set<string>> = this.store.select(selectAlreadyLoadedAssetImgUrls);
  readonly rankingLoading$: Observable<boolean> = this.store.select(selectRankingLoading);
  readonly inGameLoading$: Observable<boolean> = this.store.select(selectInGameLoading);
  readonly gamesOverviewLoading$: Observable<boolean> = this.store.select(selectGamesOverviewLoading);

  readonly currentInGameAdvertisementViewportAsset$: Observable<AdvertisementViewportAsset | null> = combineLatest([
    this.currentInGameAdvertisementAsset$,
    this.viewSize$,
  ]).pipe(map(([advertisementAsset, viewSize]) => advertisementAsset?.viewports[viewSize] ?? null));

  readonly currentRankingAdvertisementViewportAsset$: Observable<AdvertisementViewportAsset | null> = combineLatest([
    this.currentRankingAdvertisementAsset$,
    this.viewSize$,
  ]).pipe(map(([advertisementAsset, viewSize]) => advertisementAsset?.viewports[viewSize] ?? null));

  readonly currentGamesOverviewAdvertisementViewportAsset$: Observable<AdvertisementViewportAsset | null> =
    combineLatest([this.currentGamesOverviewAdvertisementAsset$, this.viewSize$]).pipe(
      map(([advertisementAsset, viewSize]) => advertisementAsset?.viewports[viewSize] ?? null),
    );

  initAllAdvertisementAssetsTypes() {
    this.store.dispatch(AdvertisementActions.initAdvertisementAssets());
  }

  getNextAdvertisementAsset(placement: AdvertisementPlace) {
    this.store.dispatch(AdvertisementActions.getNextAdvertisement({ placement }));
  }

  handleLinkClick(advertisementLink: string) {
    if (this.configFacade.nativeAppType() !== null) {
      this.nativeAppsService.goToUrl(advertisementLink);
    } else {
      window.open(advertisementLink, '_blank');
    }
  }
}
