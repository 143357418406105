import { UserTrophyType } from '@kiq/shared/enums';

export const TrophyTypeOrder: Record<UserTrophyType, number> = {
  [UserTrophyType.PLAYER_GUESSING_RANKING_WEEK]: 1,
  [UserTrophyType.PLAYER_GUESSING_RANKING_MONTH]: 2,
  [UserTrophyType.PLAYER_GUESSING_RANKING_YEAR]: 3,
  [UserTrophyType.TIC_TAC_TOE_RANKING_WEEK]: 4,
  [UserTrophyType.TIC_TAC_TOE_RANKING_MONTH]: 5,
  [UserTrophyType.TIC_TAC_TOE_RANKING_YEAR]: 6,
  [UserTrophyType.TOP_SCORER_RANKING_WEEK]: 7,
  [UserTrophyType.TOP_SCORER_RANKING_MONTH]: 8,
  [UserTrophyType.TOP_SCORER_RANKING_YEAR]: 9,
  [UserTrophyType.QUIZDUEL_RANKING_WEEK]: 10,
  [UserTrophyType.QUIZDUEL_RANKING_MONTH]: 11,
  [UserTrophyType.QUIZDUEL_RANKING_YEAR]: 12,
  [UserTrophyType.QUIZDUEL_MATCH_WINNER]: 13,
  [UserTrophyType.QUIZDUEL_PERFECT_MATCH]: 14,
  [UserTrophyType.TIC_TAC_TOE_ONLINE_VICTORIES]: 15,
  [UserTrophyType.TIC_TAC_TOE_ONLINE_MATCHES]: 16,
  [UserTrophyType.ALL_GAMES_ONLINE_OPPONENTS]: 17,
  [UserTrophyType.TIC_TAC_TOE_ONLINE_SOLVED_PLAYERS]: 18,
  [UserTrophyType.GRID_MASTER]: 19,
  [UserTrophyType.GRID_SPECIALIST]: 20,
  [UserTrophyType.TOPSCORER_SCORE_AMATEUR]: 21,
  [UserTrophyType.TOPSCORER_SCORE_PRO]: 22,
  [UserTrophyType.TOPSCORER_SCORE_LEGENDARY]: 23,
  [UserTrophyType.PLAYER_GUESSING_SOLVED_PLAYERS_AMATEUR]: 24,
  [UserTrophyType.PLAYER_GUESSING_SOLVED_PLAYERS_PRO]: 25,
  [UserTrophyType.PLAYER_GUESSING_SOLVED_PLAYERS_LEGENDARY]: 26,
  [UserTrophyType.PLAYER_INVITATIONS]: 27,
  [UserTrophyType.TIC_TAC_TOE_ONLINE_GAME_SERIES]: 28,
  [UserTrophyType.EMAIL_VERIFICATION]: 29,
  [UserTrophyType.FOUNDER]: 30,
  [UserTrophyType.WELCOME]: 31,
};
