import { AdvertisementFormat, AdvertisementPlace, AdvertisementType, ViewSize } from '@kiq/shared/enums';
import { AdvertisementViewportAsset } from './advertisement-viewport-asset';

export interface AdvertisementAsset {
  id: string;
  placement: AdvertisementPlace;
  clubName: string | null;
  type: AdvertisementType;
  viewports: Record<ViewSize, AdvertisementViewportAsset>;
}
