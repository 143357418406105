import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { UserActions } from './user.actions';
import { catchError, concatMap, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { FirebaseService } from '../service/firebase.service';
import { UserCredential } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { UserBackendService } from '../service/user-backend.service';
import { UserFacade } from '../+state/user.facade';
import { clientRoutes, GAME_ID_QUERY_PARAM, GAME_TYPE_QUERY_PARAM, INVITE_QUERY_PARAM } from '@kiq/client/util/routing';
import { Store } from '@ngrx/store';
import { selectUser } from './user.reducer';
import { SnackbarService, SnackbarType } from 'shared/util/snackbar';
import { DeviceToken, UserCreateRequest } from '@kiq/shared/types';
import { ConfigFacade } from '@kiq/client/data-access/config';
import { BackendUserUpdateType, DeviceType, FirebaseErrorCodes, NativeAppType } from '@kiq/shared/enums';
import { UserOwnerView } from '@kiq/shared/classes';
import { translate } from '@jsverse/transloco';
import { FirebaseError } from 'firebase-admin';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserEffects {
  private action = inject(Actions);
  private firebaseService = inject(FirebaseService);
  private userService = inject(UserBackendService);
  private router = inject(Router);
  public snackbar = inject(SnackbarService);
  public store = inject(Store);
  public userFacade = inject(UserFacade);
  private readonly configFacade = inject(ConfigFacade);
  nativeAppType = this.configFacade.nativeAppType;

  private readonly MAX_FILE_SIZE = 2000000;

  createNewFirebaseUser$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.createNewFirebaseUserStart),
      concatMap(({ email, password, username, favouriteClub, redirectUrlWithParams }) => {
        return this.firebaseService.signUpWithEmail$(email, password).pipe(
          switchMap((userCredential) => {
            const user = JSON.parse(JSON.stringify(userCredential));
            //TODO: use structural clone? Not working like this
            // const user = structuredClone(userCredential);
            return of(
              UserActions.createNewFirebaseUserSuccess({ user, username, favouriteClub, redirectUrlWithParams }),
            );
          }),
          catchError((error: FirebaseError) => {
            if (error.code === FirebaseErrorCodes.EMAIL_EXISTS) {
              this.handleError(translate('userDataAccess.errorEmailExists'));
            } else {
              this.handleError(translate('userDataAccess.errorCreatingFirebaseUser'));
            }
            return of(UserActions.createNewFirebaseUserFailure({ error: error.message }));
          }),
        );
      }),
    );
  });

  createNewUserWithGoogle$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.createNewUserWithGoogleStart),
      concatMap(({ redirectUrlWithParams }) => {
        return this.firebaseService.signUpWithGoogle$().pipe(
          map((userCredential: UserCredential) => {
            const user: UserCredential = JSON.parse(JSON.stringify(userCredential));
            return UserActions.createNewFirebaseUserSuccess({
              user,
              username: '',
              redirectUrlWithParams: redirectUrlWithParams,
            });
          }),
          catchError((error: string) => {
            this.handleError(translate('userDataAccess.errorCreatingUserWithGoogle'));
            return of(UserActions.createNewFirebaseUserFailure({ error }));
          }),
        );
      }),
    );
  });

  createNewUserWithApple$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.createNewUserWithAppleStart),
      concatMap(({ redirectUrlWithParams }) => {
        return this.firebaseService.signUpWithApple$().pipe(
          map((userCredential: UserCredential) => {
            const user: UserCredential = JSON.parse(JSON.stringify(userCredential));
            return UserActions.createNewFirebaseUserSuccess({
              user,
              username: '',
              redirectUrlWithParams: redirectUrlWithParams,
            });
          }),
          catchError((error: string) => {
            this.handleError(translate('userDataAccess.errorCreatingUserWithApple'));
            return of(UserActions.createNewFirebaseUserFailure({ error }));
          }),
        );
      }),
    );
  });

  onFireBaseUserCreateSuccess$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.createNewFirebaseUserSuccess),
      map(({ user, username, favouriteClub, redirectUrlWithParams }) => {
        if (!user || !user.user || !user.user.email) {
          return UserActions.createNewBackendUserFailure({ error: 'no user data available' });
        }

        const userDto: UserCreateRequest = {
          email: user.user.email,
          username: username,
          favoriteFootballTeam: favouriteClub,
        };

        return UserActions.createNewBackendUser({ user: userDto, redirectUrlWithParams });
      }),
    );
  });

  loginUserWithGoogle$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.loginUserWithGoogleStart),
      concatMap(({ redirectUrlWithParams }) => {
        return this.firebaseService.signUpWithGoogle$().pipe(
          map((userCredential: UserCredential) => {
            const userCredentialsCopy: UserCredential = JSON.parse(JSON.stringify(userCredential));
            return UserActions.loginUserWithPopupSuccess({
              userCredential: userCredentialsCopy,
              redirectUrlWithParams: redirectUrlWithParams,
            });
          }),
          catchError((errorRes: HttpErrorResponse) => {
            this.handleError(translate('userDataAccess.errorLoginUserWithGoogle'));
            return of(UserActions.loginUserWithPopupFail({ error: errorRes.error.errorMessage }));
          }),
        );
      }),
    );
  });

  loginUserWithApple$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.loginUserWithAppleStart),
      concatMap(({ redirectUrlWithParams }) => {
        return this.firebaseService.signUpWithApple$().pipe(
          map((userCredential: UserCredential) => {
            const userCredentialsCopy: UserCredential = JSON.parse(JSON.stringify(userCredential));
            return UserActions.loginUserWithPopupSuccess({
              userCredential: userCredentialsCopy,
              redirectUrlWithParams: redirectUrlWithParams,
            });
          }),
          catchError((error: string) => {
            this.handleError(translate('userDataAccess.errorLoginUserWithApple'));
            return of(UserActions.loginUserWithPopupFail({ error }));
          }),
        );
      }),
    );
  });

  onLoginWithPopupSuccess$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.loginUserWithPopupSuccess),
      concatMap(({ userCredential, redirectUrlWithParams }) => {
        return of(
          UserActions.getBackendUserAfterPopupLogin({ userCredential, redirectUrlWithParams: redirectUrlWithParams }),
        );
      }),
    );
  });

  getBackendUserAfterPopUpLogin$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.getBackendUserAfterPopupLogin),
      concatMap(({ userCredential, redirectUrlWithParams }) => {
        return this.userService.getUser$().pipe(
          map((user) => {
            return UserActions.getBackendUserSuccess({ user });
          }),
          tap(() => {
            if (redirectUrlWithParams !== undefined) {
              localStorage.setItem('wasAlreadyLoggedIn', 'true');
              this.router.navigate([''], { queryParams: undefined });
              setTimeout(() => {
                this.router.navigate([redirectUrlWithParams.url], {
                  queryParams: {
                    gameType: redirectUrlWithParams?.params?.get(GAME_TYPE_QUERY_PARAM) ?? undefined,
                    invite: redirectUrlWithParams?.params?.get(INVITE_QUERY_PARAM) ?? undefined,
                    id: redirectUrlWithParams?.params?.get(GAME_ID_QUERY_PARAM) ?? undefined,
                  },
                });
              }, 500);
            } else {
              const wasAlreadyLoggedInToken = localStorage.getItem('wasAlreadyLoggedIn');
              if (!wasAlreadyLoggedInToken) {
                localStorage.setItem('wasAlreadyLoggedIn', 'true');
                this.router.navigate([`/${clientRoutes.PROFILE.BASE}/${clientRoutes.PROFILE.PROFILE_SETTINGS}`]);
              }
            }
          }),
          catchError((errorRes: HttpErrorResponse) => {
            if (errorRes.status === 404) {
              return of(UserActions.getBackendUserFailureAfterPopupLogin({ userCredential }));
            } else {
              return of(UserActions.getBackendUserFailure({ error: errorRes.error.errorMessage }));
            }
          }),
        );
      }),
    );
  });

  getBackendUserFailedAfterPopupLogin$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.getBackendUserFailureAfterPopupLogin),
      concatMap((userCredential) => {
        if (userCredential.userCredential.user.email) {
          const userDto: UserCreateRequest = {
            email: userCredential.userCredential.user.email,
            username: '',
          };
          return of(UserActions.createNewBackendUser({ user: userDto }));
        }
        return of(UserActions.getBackendUserFailureAfterPopupLoginFail({ error: 'backend user not created' }));
      }),
    );
  });

  createNewBackendUser$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.createNewBackendUser),
      concatMap(({ user, redirectUrlWithParams }) => {
        return this.userService.createUser$(user).pipe(
          map((user) => {
            return UserActions.createNewBackendUserSuccess({ user });
          }),
          tap(() => {
            if (redirectUrlWithParams !== undefined) {
              localStorage.setItem('wasAlreadyLoggedIn', 'true');
              this.router.navigate([''], { queryParams: undefined });
              setTimeout(() => {
                this.router.navigate([redirectUrlWithParams.url], {
                  queryParams: {
                    gameType: redirectUrlWithParams?.params?.get(GAME_TYPE_QUERY_PARAM) ?? undefined,
                    invite: redirectUrlWithParams?.params?.get(INVITE_QUERY_PARAM) ?? undefined,
                    id: redirectUrlWithParams?.params?.get(GAME_ID_QUERY_PARAM) ?? undefined,
                  },
                });
              }, 500);
            } else {
              const wasAlreadyLoggedInToken = localStorage.getItem('wasAlreadyLoggedIn');
              if (!wasAlreadyLoggedInToken) {
                localStorage.setItem('wasAlreadyLoggedIn', 'true');
                this.router.navigate([`/${clientRoutes.PROFILE.BASE}/${clientRoutes.PROFILE.PROFILE_SETTINGS}`]);
              }
            }
          }),
          catchError((error: string) => {
            this.handleError(translate('userDataAccess.errorCreatingUser'));
            return of(UserActions.createNewBackendUserFailure({ error }));
          }),
        );
      }),
    );
  });

  loginFirebaseUser$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.loginStart),
      concatMap(({ email, password, redirectUrlWithParams }) => {
        return this.firebaseService.loginWithEmail$(email, password).pipe(
          map((userCredential) => {
            return UserActions.loginSuccess({ redirectUrlWithParams });
          }),
          catchError((error: FirebaseError) => {
            if (error.code === FirebaseErrorCodes.INVALID_LOGIN_CREDENTIALS) {
              this.handleError(translate('userDataAccess.errorInvalidCredentials'));
            } else {
              this.handleError(translate('userDataAccess.errorWhileLoggingInUser'));
            }
            return of(UserActions.loginFailure({ error: error.message }));
          }),
        );
      }),
    );
  });

  onLoginSuccess$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.loginSuccess),
      concatMap((redirectUrlWithParams) => {
        return of(UserActions.getBackendUser(redirectUrlWithParams));
      }),
    );
  });

  getBackendUser$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.getBackendUser),
      concatMap(({ redirectUrlWithParams }) => {
        return this.userService.getUser$().pipe(
          map((user) => {
            return UserActions.getBackendUserSuccess({ user });
          }),
          tap(() => {
            if (redirectUrlWithParams !== undefined) {
              this.router.navigate([''], { queryParams: undefined });
              setTimeout(() => {
                this.router.navigate([redirectUrlWithParams.url], {
                  queryParams: {
                    gameType: redirectUrlWithParams?.params?.get(GAME_TYPE_QUERY_PARAM) ?? undefined,
                    invite: redirectUrlWithParams?.params?.get(INVITE_QUERY_PARAM) ?? undefined,
                    id: redirectUrlWithParams?.params?.get(GAME_ID_QUERY_PARAM) ?? undefined,
                  },
                });
              }, 500);
            }
          }),
          catchError((error: string) => {
            this.handleError(translate('userDataAccess.errorWhileLoadingUserData'));
            return of(UserActions.getBackendUserFailure({ error }));
          }),
        );
      }),
    );
  });

  onGetBackendUserSuccess$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.getBackendUserSuccess),
      concatMap(() => {
        return of(UserActions.setProfileNotification({ profileNotification: false }));
      }),
    );
  });

  updateProfileImage$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.uploadProfileImageStart),
      concatMap(({ file }) => {
        if (file.size > this.MAX_FILE_SIZE) {
          const errorMesage = 'Die Datei ist zu groß, bitte wähle eine Datei mit maximal 2MB aus.';

          this.snackbar.show({ message: errorMesage, type: SnackbarType.ERROR });

          return of(
            UserActions.uploadProfileImageFail({
              error: 'Die Datei ist zu groß, bitte wähle eine Datei mit maximal 2MB aus.',
            }),
          );
        }

        const formData = new FormData();
        const filename = file.name;
        formData.append('profileImage', file, filename);

        return this.userService.updateImage$(formData).pipe(
          map((imageUrl: string) => {
            this.handleSuccess(translate('userDataAccess.profilePictureSuccessfullyUpdated'));
            return UserActions.uploadProfileImageSuccess({ imageUrl });
          }),
          catchError((error) => {
            this.handleError(translate('userDataAccess.errorUpdatingProfilePicture'));
            return of(UserActions.uploadProfileImageFail({ error }));
          }),
        );
      }),
    );
  });

  onUploadImageSuccess$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.uploadProfileImageSuccess),
      concatMap(() => {
        return of(UserActions.getBackendUser({}));
      }),
    );
  });

  logoutFirebaseUser$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.logoutFirebaseUserStart),
      concatMap(() => {
        return this.firebaseService.logoutFirebaseUser$().pipe(
          map(() => {
            this.handleSuccess(translate('userDataAccess.logoutSuccessful'));
            localStorage.removeItem('wasAlreadyLoggedIn');
            return UserActions.logoutFirebaseUserSuccess();
          }),
          tap(() => {
            this.router.navigate([`/${clientRoutes.PROFILE.BASE}`]);
          }),
          catchError((error: string) => {
            this.handleError(translate('userDataAccess.errorWhileLoggingOut'));
            return of(UserActions.logoutFirebaseUserFail({ error }));
          }),
        );
      }),
    );
  });

  onLogoutSuccessRemoveDeviceToken$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.logoutFirebaseUserSuccess),
      filter(() => {
        const isInApp = this.configFacade.nativeAppType() !== null;
        const storedAppInstallId = localStorage.getItem('appInstallId');
        return isInApp && storedAppInstallId !== null;
      }),
      concatMap(() => {
        const appInstallId = localStorage.getItem('appInstallId')!;

        return of(UserActions.deleteUserDeviceToken({ appInstallId }));
      }),
    );
  });

  resetPassword = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.resetPasswordStart),
      concatMap(({ email }) => {
        return this.userService.resetPassword$(email).pipe(
          map(() => {
            this.handleSuccess(translate('userDataAccess.passwordUpdatedSeeMails'));
            return UserActions.resetPasswordSuccess();
          }),
          catchError((error) => {
            this.handleError(translate('userDataAccess.errorWhileUpdatingPassword', error));
            return of(UserActions.resetPasswordFail());
          }),
        );
      }),
    );
  });

  verifyAndConfirmPasswordAfterReset = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.verifyAndConfirmPasswordResetStart),
      concatMap(({ oobCode, password }) => {
        return this.firebaseService.verifyAndConfirmPassword$(oobCode, password).pipe(
          map(() => {
            this.handleSuccess(translate('userDataAccess.changesSavedSuccessfully'));

            return UserActions.verifyAndConfirmPasswordResetSuccess();
          }),
          catchError((error) => {
            this.handleError(translate('userDataAccess.errorVerifyingPasswordReset'), error);
            return of(UserActions.verifyAndConfirmPasswordResetFail());
          }),
        );
      }),
    );
  });

  updatePassword = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updatePasswordStart),
      concatMap(({ oldPassword, newPassword }) =>
        this.firebaseService.reauthenticateWithCredential$(oldPassword).pipe(
          concatMap(() =>
            this.firebaseService.updatePassword$(newPassword).pipe(
              map(() => {
                this.handleSuccess(translate('userDataAccess.changesSavedSuccessfully'));
                return UserActions.updatePasswordSuccess();
              }),
              catchError((error) => {
                this.handleError(translate('userDataAccess.errorWhileChangingPassword'));
                return of(UserActions.updatePasswordFail({ error }));
              }),
            ),
          ),
          catchError((error) => {
            this.handleError(translate('userDataAccess.errorWhileChangingPasswordSeeInput'));
            return of(UserActions.updatePasswordFail({ error }));
          }),
        ),
      ),
    );
  });

  deleteAccount = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.deleteAccountStart),
      concatMap(() => {
        return this.userService.deleteUser$().pipe(
          map(() => {
            this.handleSuccess(translate('userDataAccess.accountDeletedSuccessfully'));
            this.router.navigate(['']);
            return UserActions.deleteAccountSuccess();
          }),
          catchError((error) => {
            this.handleError(translate('userDataAccess.errorWhileDeletingAccount'), error);
            return of(UserActions.deleteAccountFail({ error }));
          }),
        );
      }),
    );
  });

  deleteProfileImage = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.deleteProfileImageStart),
      concatMap(() => {
        return this.userService.deleteImage$().pipe(
          map(() => {
            this.handleSuccess(translate('userDataAccess.profilePictureDeletedSuccessfully'));
            this.router.navigate([`/${clientRoutes.LANDING}/${clientRoutes.PROFILE.BASE}`]);
            return UserActions.deleteProfileImageSuccess();
          }),
          catchError((error) => {
            this.handleError(translate('userDataAccess.errorWhileDeletingProfilePicture'), error);
            return of(UserActions.deleteProfileImageFail({ error }));
          }),
        );
      }),
    );
  });

  onDeleteImageSuccess$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.deleteProfileImageSuccess),
      concatMap(() => {
        return of(UserActions.getBackendUser({}));
      }),
    );
  });

  onSetProfileNotification$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.setProfileNotification),
      concatMap((profileNot) => {
        localStorage.setItem('alreadyVisitedKIKKZ', profileNot ? 'true' : 'false');
        return of(UserActions.setProfileNotificationSuccess());
      }),
    );
  });

  onGetProfileNotification$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.getProfileNotification),
      concatMap(() => {
        const alreadyVisitedSite = localStorage.getItem('alreadyVisitedKIKKZ');
        let profileNotification = true;
        if (alreadyVisitedSite) {
          if (alreadyVisitedSite === 'true') {
            profileNotification = false;
          }
        }
        return of(UserActions.getProfileNotificationSuccess({ profileNotification }));
      }),
    );
  });

  sendEmailVerification = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.sendEmailVerification),
      concatMap(() => {
        return this.userService.sendUserEmailVerificationMessage$().pipe(
          map(() => {
            this.handleSuccess(translate('userDataAccess.verificationMailSuccessfullySend'));
            return UserActions.sendEmailVerificationSuccess();
          }),
          catchError((error) => {
            this.handleError(translate('userDataAccess.errorWhileSendingVerificationMail'), error);
            return of(UserActions.sendEmailVerificationFail(error));
          }),
        );
      }),
    );
  });

  onSetNewUserInvitationLink$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.setNewUserInvitationLink),
      concatMap(() => {
        return this.userService.setNewUserInvitationLink$().pipe(
          map((user) => {
            this.handleSuccess(translate('userDataAccess.invitationLinkUpdatedSuccessfully'));
            return UserActions.setNewUserInvitationLinkSuccess({ user });
          }),
          catchError((error) => {
            this.handleError(translate('userDataAccess.errorWhileUpdatingInvitationLink'));
            return of(UserActions.setNewUserInvitationLinkFail({ error }));
          }),
        );
      }),
    );
  });

  setUserDeviceToken$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.setUserDeviceToken),
      filter(({ appInstallId, fcmToken }) => {
        const storedAppInstallId = localStorage.getItem('appInstallId');
        const storedFcmToken = localStorage.getItem('fcmToken');
        return storedAppInstallId !== appInstallId || storedFcmToken !== fcmToken;
      }),
      withLatestFrom(this.firebaseService.firebaseUser$),
      concatMap(([{ appInstallId, fcmToken }, firebaseUser]) => {
        if (!appInstallId || !fcmToken || !firebaseUser) {
          const error = 'missing appInstallId or fcmToken or firebaseUser';
          console.error(error);
          return of(UserActions.setUserDeviceTokenFail({ error }));
        }
        if (!this.nativeAppType()) {
          const error = 'no supported native app environment';
          console.error(error);
          return of(UserActions.setUserDeviceTokenFail({ error }));
        }
        let deviceType: DeviceType;
        if (this.nativeAppType() === NativeAppType.IosApp) {
          deviceType = DeviceType.IOS;
        } else {
          deviceType = DeviceType.ANDROID;
        }
        const deviceToken: DeviceToken = {
          deviceType: deviceType,
          appInstallId: appInstallId,
          fcmToken: fcmToken,
          userFirebaseId: firebaseUser.uid,
        };
        return this.userService.setUserDeviceToken$(deviceToken).pipe(
          map((deviceToken) => {
            localStorage.setItem('appInstallId', appInstallId);
            localStorage.setItem('fcmToken', fcmToken);
            return UserActions.setUserDeviceTokenSuccess({ deviceToken, fcmToken });
          }),
          catchError((error: string) => {
            console.error(error);
            return of(UserActions.setUserDeviceTokenFail({ error: error }));
          }),
        );
      }),
    );
  });

  deleteUserDeviceToken$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.deleteUserDeviceToken),
      filter(() => {
        const hasStoredAppInstallId = Boolean(localStorage.getItem('appInstallId'));

        return hasStoredAppInstallId;
      }),
      concatMap(({ appInstallId }) => {
        return this.userService.deleteUserDeviceToken$(appInstallId).pipe(
          map(() => {
            localStorage.removeItem('appInstallId');
            localStorage.removeItem('fcmToken');
            return UserActions.deleteUserDeviceTokenSuccess();
          }),
          catchError((error: string) => {
            console.error(error);
            return of(UserActions.deleteUserDeviceTokenFail({ error: error }));
          }),
        );
      }),
    );
  });

  updateBackendUser = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateBackendUserStart),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      concatMap(([{ user, updateType }, currentUser]) => {
        if (!currentUser) {
          this.handleError(translate('userDataAccess.noUserDataFoundToUpdate'));
          return of(UserActions.updateBackendUserFail({ error: translate('userDataAccess.noUserDataFoundToUpdate') }));
        }

        const updatedUser: UserOwnerView = {
          ...currentUser,
          ...user,
        };
        return this.userService.updateUser$(updatedUser).pipe(
          map((user) => {
            if (user.email !== currentUser.email && updateType === BackendUserUpdateType.EMAIL) {
              this.handleSuccess(translate('userDataAccess.emailUpdatedSuccessfully'));
              this.router.navigate(['']);
            } else if (
              updateType !== BackendUserUpdateType.NOTIFICATION_SETTINGS &&
              updateType !== BackendUserUpdateType.LANGUAGE &&
              updateType !== BackendUserUpdateType.CHALLENGEABILITY_SETTINGS
            ) {
              this.handleSuccess(translate('userDataAccess.changesSavedSuccessfully'));
            }
            return UserActions.updateBackendUserSuccess({ user });
          }),
          catchError((error) => {
            this.handleError(translate('userDataAccess.errorWhileUpdatingEmail'), error);
            return of(UserActions.updateBackendUserFail({ error }));
          }),
        );
      }),
    );
  });

  updateUsername = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateUsername),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      map(([{ username }, currentUser]) => {
        if (!currentUser) {
          const noUserError = translate('userDataAccess.noUserDataFoundToUpdate');
          this.handleError(noUserError);
          return UserActions.updateBackendUserFail({ error: noUserError });
        }
        const updatedUser: UserOwnerView = {
          ...currentUser,
          username: username,
        };
        return UserActions.updateBackendUserStart({ user: updatedUser, updateType: BackendUserUpdateType.USERNAME });
      }),
    );
  });

  updateFirstName = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateFirstname),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      map(([{ firstname }, currentUser]) => {
        if (!currentUser) {
          const noUserError = translate('userDataAccess.noUserDataFoundToUpdate');
          this.handleError(noUserError);
          return UserActions.updateBackendUserFail({ error: noUserError });
        }
        const updatedUser: UserOwnerView = {
          ...currentUser,
          firstname: firstname,
        };
        return UserActions.updateBackendUserStart({
          user: updatedUser,
          updateType: BackendUserUpdateType.FIRSTNAME,
        });
      }),
    );
  });

  updateLastName = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateLastname),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      map(([{ lastname }, currentUser]) => {
        if (!currentUser) {
          const noUserError = translate('userDataAccess.noUserDataFoundToUpdate');
          this.handleError(noUserError);
          return UserActions.updateBackendUserFail({ error: noUserError });
        }
        const updatedUser: UserOwnerView = {
          ...currentUser,
          lastname: lastname,
        };
        return UserActions.updateBackendUserStart({
          user: updatedUser,
          updateType: BackendUserUpdateType.LASTNAME,
        });
      }),
    );
  });

  updateFavouriteClub = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateFavouriteClub),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      map(([{ favClubId }, currentUser]) => {
        if (!currentUser) {
          const noUserError = translate('userDataAccess.noUserDataFoundToUpdate');
          this.handleError(noUserError);
          return UserActions.updateBackendUserFail({ error: noUserError });
        }
        const updatedUser: UserOwnerView = {
          ...currentUser,
          favoriteFootballTeam: favClubId,
        };
        return UserActions.updateBackendUserStart({
          user: updatedUser,
          updateType: BackendUserUpdateType.USER_FAVOURITE_CLUB,
        });
      }),
    );
  });

  updateFootballPlayerRole = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateUserFootballRole),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      map(([{ userFootballRole }, currentUser]) => {
        if (!currentUser) {
          const noUserError = translate('userDataAccess.noUserDataFoundToUpdate');
          this.handleError(noUserError);
          return UserActions.updateBackendUserFail({ error: noUserError });
        }
        const updatedUser: UserOwnerView = {
          ...currentUser,
          userFootballRole: userFootballRole,
        };
        return UserActions.updateBackendUserStart({
          user: updatedUser,
          updateType: BackendUserUpdateType.USER_FOOTBALL_ROLE,
        });
      }),
    );
  });

  updateUserFootballPlayerPosition = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateUserFootballPlayerPosition),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      map(([{ userFootballPlayerPosition }, currentUser]) => {
        if (!currentUser) {
          const noUserError = translate('userDataAccess.noUserDataFoundToUpdate');
          this.handleError(noUserError);
          return UserActions.updateBackendUserFail({ error: noUserError });
        }
        const updatedUser: UserOwnerView = {
          ...currentUser,
          footballPlayerPosition: userFootballPlayerPosition,
        };
        return UserActions.updateBackendUserStart({
          user: updatedUser,
          updateType: BackendUserUpdateType.USER_FOOTBALL_PLAYER_POSITION,
        });
      }),
    );
  });

  updateUserEmail = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateEmailAddress),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      map(([{ email }, currentUser]) => {
        if (!currentUser) {
          const noUserError = translate('userDataAccess.noUserDataFoundToUpdate');
          this.handleError(noUserError);
          return UserActions.updateBackendUserFail({ error: noUserError });
        }
        const updatedUser: UserOwnerView = {
          ...currentUser,
          email: email,
        };
        return UserActions.updateBackendUserStart({
          user: updatedUser,
          updateType: BackendUserUpdateType.EMAIL,
        });
      }),
    );
  });

  updateUserYearOfBirth = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateUserYearOfBirth),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      map(([{ yearOfBirth }, currentUser]) => {
        if (!currentUser) {
          const noUserError = translate('userDataAccess.noUserDataFoundToUpdate');
          this.handleError(noUserError);
          return UserActions.updateBackendUserFail({ error: noUserError });
        }
        const updatedUser: UserOwnerView = {
          ...currentUser,
          yearOfBirth: yearOfBirth,
        };
        return UserActions.updateBackendUserStart({
          user: updatedUser,
          updateType: BackendUserUpdateType.YEAR_OF_BIRTH,
        });
      }),
    );
  });

  updateUserLanguage = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateUserLanguage),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      map(([{ language }, currentUser]) => {
        if (!currentUser) {
          const noUserError = translate('userDataAccess.noUserDataFoundToUpdate');
          this.handleError(noUserError);
          return UserActions.updateBackendUserFail({ error: noUserError });
        }
        const updatedUser: UserOwnerView = {
          ...currentUser,
          language: language,
        };
        return UserActions.updateBackendUserStart({
          user: updatedUser,
          updateType: BackendUserUpdateType.LANGUAGE,
        });
      }),
    );
  });

  updateUserNotificationSettings = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateUserNotificationSettings),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      map(([{ notificationSettings }, currentUser]) => {
        if (!currentUser) {
          const noUserError = translate('userDataAccess.noUserDataFoundToUpdate');
          this.handleError(noUserError);
          return UserActions.updateBackendUserFail({ error: noUserError });
        }
        const updatedUser: UserOwnerView = {
          ...currentUser,
          notificationSettings: notificationSettings,
        };
        return UserActions.updateBackendUserStart({
          user: updatedUser,
          updateType: BackendUserUpdateType.NOTIFICATION_SETTINGS,
        });
      }),
    );
  });

  updateUserChallengeableSettings = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.updateUserChallengeabilitySettings),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      map(([{ allowInviteFromUser }, currentUser]) => {
        if (!currentUser) {
          const noUserError = translate('userDataAccess.noUserDataFoundToUpdate');
          this.handleError(noUserError);
          return UserActions.updateBackendUserFail({ error: noUserError });
        }
        const updatedUser: UserOwnerView = {
          ...currentUser,
          allowInviteFromUser,
        };
        return UserActions.updateBackendUserStart({
          user: updatedUser,
          updateType: BackendUserUpdateType.CHALLENGEABILITY_SETTINGS,
        });
      }),
    );
  });

  setLandingAlreadyVisitedToken$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.setLandingAlreadyVisitedToken),
      concatMap((alreadyVisited) => {
        localStorage.setItem('landingAlreadyVisited', alreadyVisited ? 'true' : 'false');
        return of(UserActions.setLandingAlreadyVisitedTokenSuccess());
      }),
    );
  });

  retrieveLandingAlreadyVisitedToken$ = createEffect(() => {
    return this.action.pipe(
      ofType(UserActions.retrieveLandingAlreadyVisitedToken),
      concatMap(() => {
        const landingAlreadyVisited = localStorage.getItem('landingAlreadyVisited');
        let alreadyVisited = false;
        if (landingAlreadyVisited) {
          if (landingAlreadyVisited === 'true') {
            alreadyVisited = true;
          }
        }
        return of(UserActions.retrieveLandingAlreadyVisitedTokenSuccess({ alreadyVisited }));
      }),
    );
  });

  private handleError(message: string, error?: unknown): void {
    this.snackbar.show({ message, type: SnackbarType.ERROR });
    if (error) {
      console.error(error);
    }
  }

  private handleSuccess(message: string): void {
    this.snackbar.show({ message, type: SnackbarType.SUCCESS });
  }
}
