import { GameTopscorerQuestionOption } from './game-topscorer-question-option';
import { AsyncQuestion } from './async-question';
import { FootballPlayer } from './football-player';

export type AsyncDuellAnswer = {
  questionTimeoutReached: boolean;
  answerIsCorrect?: boolean;
  ticTacToe?: FootballPlayer;
  topscorer?: GameTopscorerQuestionOption;
  transferHistory?: FootballPlayer;
  question?: AsyncQuestion;
};
