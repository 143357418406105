import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { advertisementFeature } from './+state/advertisement.reducer';
import { AdvertisementFacade } from './+state/advertisement.facade';
import { AdvertisementEffects } from './+state/advertisement.effects';
import { AdvertisementService } from './service/advertisement.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(advertisementFeature),
    EffectsModule.forFeature([AdvertisementEffects]),
  ],
  providers: [AdvertisementFacade, AdvertisementService],
})
export class ClientDataAccessAdvertisementModule {}
