import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { AdvertisementActions } from './advertisement.actions';
import { AdvertisementAsset } from '@kiq/shared/types';
import { AdvertisementPlace } from '@kiq/shared/enums';
import { clubAdvertisementAssets, generalAdvertisementAssets } from '../const/advertisement-maps';

export interface AdvertisementState {
  error: string | null;
  rankingLoading: boolean;
  inGameLoading: boolean;
  gamesOverviewLoading: boolean;
  advertisementAsset: AdvertisementAsset | null;
  alreadyLoadedAssetImgUrls: Set<string>;
  generalRankingAssets: AdvertisementAsset[];
  generalInGameAssets: AdvertisementAsset[];
  generalGamesOverviewAssets: AdvertisementAsset[];
  clubRankingAssets: AdvertisementAsset[];
  clubInGameAssets: AdvertisementAsset[];
  clubGamesOverviewAssets: AdvertisementAsset[];
  currentInGameAdvertisementAssetIndex: number | null;
  currentGamesOverviewAdvertisementAssetIndex: number | null;
  currentRankingAdvertisementAssetIndex: number | null;
  rankingAdvertisementAssetsQueue: AdvertisementAsset[];
  inGameAdvertisementAssetsQueue: AdvertisementAsset[];
  gamesOverviewAdvertisementAssetsQueue: AdvertisementAsset[];
}

export const initialAdvertisementState: AdvertisementState = {
  rankingLoading: false,
  inGameLoading: false,
  gamesOverviewLoading: false,
  error: null,
  advertisementAsset: null,

  alreadyLoadedAssetImgUrls: new Set<string>(),
  generalRankingAssets: generalAdvertisementAssets.get(AdvertisementPlace.RANKING) ?? [],
  generalInGameAssets: generalAdvertisementAssets.get(AdvertisementPlace.IN_GAME) ?? [],
  generalGamesOverviewAssets: generalAdvertisementAssets.get(AdvertisementPlace.GAMES_OVERVIEW) ?? [],
  clubRankingAssets: [],
  clubInGameAssets: [],
  clubGamesOverviewAssets: [],
  currentRankingAdvertisementAssetIndex: null,
  currentInGameAdvertisementAssetIndex: null,
  currentGamesOverviewAdvertisementAssetIndex: null,
  rankingAdvertisementAssetsQueue: generalAdvertisementAssets.get(AdvertisementPlace.RANKING) ?? [],
  inGameAdvertisementAssetsQueue: generalAdvertisementAssets.get(AdvertisementPlace.IN_GAME) ?? [],
  gamesOverviewAdvertisementAssetsQueue: generalAdvertisementAssets.get(AdvertisementPlace.GAMES_OVERVIEW) ?? [],
};

export const advertisementFeature = createFeature({
  name: 'advertisement',
  reducer: createReducer(
    initialAdvertisementState,
    immerOn(AdvertisementActions.preloadAdvertisementStart, (state, { placement }) => {
      setLoadingState(state, true, placement);
    }),
    immerOn(AdvertisementActions.preloadAdvertisementSuccess, (state, { urlToPreload, placement }) => {
      setLoadingState(state, false, placement);

      const alreadyLoadedAssetImgUrls = state.alreadyLoadedAssetImgUrls;
      alreadyLoadedAssetImgUrls.add(urlToPreload);

      state.alreadyLoadedAssetImgUrls = alreadyLoadedAssetImgUrls;
    }),
    immerOn(AdvertisementActions.preloadAdvertisementFailure, (state, { placement }) => {
      setLoadingState(state, false, placement);
    }),
    immerOn(AdvertisementActions.preloadMultiAdvertisementsStart, (state) => {
      setLoadingState(state, true);
    }),
    immerOn(AdvertisementActions.preloadMultiAdvertisementsSuccess, (state, { urlsToPreload }) => {
      setLoadingState(state, false);

      const alreadyLoadedAssetImgUrls = state.alreadyLoadedAssetImgUrls;
      urlsToPreload.forEach((urlToPreload) => {
        alreadyLoadedAssetImgUrls.add(urlToPreload);
      });

      state.alreadyLoadedAssetImgUrls = alreadyLoadedAssetImgUrls;
    }),
    immerOn(AdvertisementActions.preloadMultiAdvertisementsFailure, (state) => {
      setLoadingState(state, false);
    }),
    immerOn(AdvertisementActions.setClubAdvertisementsStart, (state, { clubName }) => {
      state.clubRankingAssets = clubAdvertisementAssets.get(clubName ?? '')?.get(AdvertisementPlace.RANKING) ?? [];
      state.clubInGameAssets = clubAdvertisementAssets.get(clubName ?? '')?.get(AdvertisementPlace.IN_GAME) ?? [];
      state.clubGamesOverviewAssets =
        clubAdvertisementAssets.get(clubName ?? '')?.get(AdvertisementPlace.GAMES_OVERVIEW) ?? [];

      const rankingAdvertisementAssetsQueue = shuffleArray([...state.clubRankingAssets, ...state.generalRankingAssets]);
      const inGameAdvertisementAssetsQueue = shuffleArray([...state.clubInGameAssets, ...state.generalInGameAssets]);
      const gamesOverviewAdvertisementAssetsQueue = shuffleArray([
        ...state.clubGamesOverviewAssets,
        ...state.generalGamesOverviewAssets,
      ]);

      state.currentRankingAdvertisementAssetIndex = Math.floor(Math.random() * rankingAdvertisementAssetsQueue.length);
      state.currentInGameAdvertisementAssetIndex = Math.floor(Math.random() * inGameAdvertisementAssetsQueue.length);
      state.currentGamesOverviewAdvertisementAssetIndex = Math.floor(
        Math.random() * gamesOverviewAdvertisementAssetsQueue.length,
      );

      state.rankingAdvertisementAssetsQueue = rankingAdvertisementAssetsQueue;
      state.inGameAdvertisementAssetsQueue = inGameAdvertisementAssetsQueue;
      state.gamesOverviewAdvertisementAssetsQueue = gamesOverviewAdvertisementAssetsQueue;
    }),
    immerOn(AdvertisementActions.getNextAdvertisement, (state, { placement }) => {
      const resetQueue = (
        clubAssets: AdvertisementAsset[],
        generalAssets: AdvertisementAsset[],
      ): AdvertisementAsset[] => shuffleArray([...clubAssets, ...generalAssets]);

      const getPlacementData = (placement: AdvertisementPlace) => {
        switch (placement) {
          case AdvertisementPlace.RANKING:
            return {
              currentIndex: state.currentRankingAdvertisementAssetIndex,
              queue: state.rankingAdvertisementAssetsQueue,
              clubAssets: state.clubRankingAssets,
              generalAssets: state.generalRankingAssets,
            };
          case AdvertisementPlace.IN_GAME:
            return {
              currentIndex: state.currentInGameAdvertisementAssetIndex,
              queue: state.inGameAdvertisementAssetsQueue,
              clubAssets: state.clubInGameAssets,
              generalAssets: state.generalInGameAssets,
            };
          case AdvertisementPlace.GAMES_OVERVIEW:
            return {
              currentIndex: state.currentGamesOverviewAdvertisementAssetIndex,
              queue: state.gamesOverviewAdvertisementAssetsQueue,
              clubAssets: state.clubGamesOverviewAssets,
              generalAssets: state.generalGamesOverviewAssets,
            };
        }
      };

      const placementData = getPlacementData(placement);
      let { currentIndex, queue, clubAssets, generalAssets } = placementData;

      if (currentIndex !== null) {
        queue.splice(currentIndex, 1);
      }

      if (queue.length === 0) {
        queue = resetQueue(clubAssets, generalAssets);
      }

      const randomIndex = Math.floor(Math.random() * queue.length);

      switch (placement) {
        case AdvertisementPlace.RANKING:
          state.currentRankingAdvertisementAssetIndex = randomIndex;
          state.rankingAdvertisementAssetsQueue = queue;
          break;
        case AdvertisementPlace.IN_GAME:
          state.currentInGameAdvertisementAssetIndex = randomIndex;
          state.inGameAdvertisementAssetsQueue = queue;
          break;
        case AdvertisementPlace.GAMES_OVERVIEW:
          state.currentGamesOverviewAdvertisementAssetIndex = randomIndex;
          state.gamesOverviewAdvertisementAssetsQueue = queue;
          break;
      }
    }),
    immerOn(AdvertisementActions.initAdvertisementAssets, (state) => {
      state.currentRankingAdvertisementAssetIndex = Math.floor(
        Math.random() * state.rankingAdvertisementAssetsQueue.length,
      );
      state.currentInGameAdvertisementAssetIndex = Math.floor(
        Math.random() * state.inGameAdvertisementAssetsQueue.length,
      );
      state.currentGamesOverviewAdvertisementAssetIndex = Math.floor(
        Math.random() * state.gamesOverviewAdvertisementAssetsQueue.length,
      );
    }),
  ),
});

export const {
  name, // feature name
  selectError,
  selectAdvertisementAsset,
  selectAlreadyLoadedAssetImgUrls,
  selectClubGamesOverviewAssets,
  selectClubInGameAssets,
  selectClubRankingAssets,
  selectGeneralGamesOverviewAssets,
  selectGeneralInGameAssets,
  selectGeneralRankingAssets,
  selectCurrentGamesOverviewAdvertisementAssetIndex,
  selectCurrentInGameAdvertisementAssetIndex,
  selectCurrentRankingAdvertisementAssetIndex,
  selectGamesOverviewAdvertisementAssetsQueue,
  selectInGameAdvertisementAssetsQueue,
  selectRankingAdvertisementAssetsQueue,
  selectInGameLoading,
  selectGamesOverviewLoading,
  selectRankingLoading,
} = advertisementFeature;

export const selectCurrentRankingAdvertisementAsset = createSelector(
  selectCurrentRankingAdvertisementAssetIndex,
  selectRankingAdvertisementAssetsQueue,
  (currentIndex, assets) => {
    if (currentIndex === null) return null;

    return assets[currentIndex];
  },
);

export const selectCurrentInGameAdvertisementAsset = createSelector(
  selectCurrentInGameAdvertisementAssetIndex,
  selectInGameAdvertisementAssetsQueue,
  (currentIndex, assets) => {
    if (currentIndex === null) return null;

    return assets[currentIndex];
  },
);

export const selectCurrentGamesOverviewAdvertisementAsset = createSelector(
  selectCurrentGamesOverviewAdvertisementAssetIndex,
  selectGamesOverviewAdvertisementAssetsQueue,
  (currentIndex, assets) => {
    if (currentIndex === null) return null;

    return assets[currentIndex];
  },
);

function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

function setLoadingState(state: AdvertisementState, loading: boolean, placement?: AdvertisementPlace): void {
  if (!placement) {
    state.rankingLoading = loading;
    state.inGameLoading = loading;
    state.gamesOverviewLoading = loading;
  }

  switch (placement) {
    case AdvertisementPlace.RANKING: {
      state.rankingLoading = loading;
      break;
    }
    case AdvertisementPlace.IN_GAME: {
      state.inGameLoading = loading;
      break;
    }
    case AdvertisementPlace.GAMES_OVERVIEW: {
      state.gamesOverviewLoading = loading;
      break;
    }
  }
}
