import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { AdvertisementFacade } from './advertisement.facade';
import { catchError, concatMap, filter, forkJoin, map, of, withLatestFrom } from 'rxjs';
import { AdvertisementActions } from './advertisement.actions';
import { ConfigFacade } from '@kiq/client/data-access/config';
import { AdvertisementService } from '../service/advertisement.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { AdvertisementPlace } from '@kiq/shared/enums';
import { UserFacade } from '@kiq/client/data-access/user';
import { AdvertisementAsset } from '@kiq/shared/types';

@Injectable()
export class AdvertisementEffects {
  private actions = inject(Actions);
  private advertisementFacade = inject(AdvertisementFacade);
  private configFacade = inject(ConfigFacade);
  private userFacade = inject(UserFacade);
  private advertisementService = inject(AdvertisementService);
  private viewSize$ = toObservable(this.configFacade.viewSize);

  onGetNextAdvertisementAsset = createEffect(() => {
    return this.actions.pipe(
      ofType(AdvertisementActions.getNextAdvertisement),
      concatLatestFrom(() => [
        this.advertisementFacade.currentRankingAdvertisementAsset$,
        this.advertisementFacade.currentInGameAdvertisementAsset$,
        this.advertisementFacade.currentGamesOverviewAdvertisementAsset$,
        this.viewSize$,
      ]),
      filter(
        ([
          { placement },
          currentRankingAdvertisementAsset,
          currentInGameAdvertisementAsset,
          currentGamesOverviewAdvertisementAsset,
        ]) => {
          return (
            (placement === AdvertisementPlace.RANKING && currentRankingAdvertisementAsset != null) ||
            (placement === AdvertisementPlace.IN_GAME && currentInGameAdvertisementAsset != null) ||
            (placement === AdvertisementPlace.GAMES_OVERVIEW && currentGamesOverviewAdvertisementAsset != null)
          );
        },
      ),
      map(
        ([
          { placement },
          currentRankingAdvertisementAsset,
          currentInGameAdvertisementAsset,
          currentGamesOverviewAdvertisementAsset,
          viewSize,
        ]) => {
          let urlToPreload: string;

          switch (placement) {
            case AdvertisementPlace.IN_GAME: {
              urlToPreload = (currentInGameAdvertisementAsset as AdvertisementAsset).viewports[viewSize].imageUrl;
              break;
            }
            case AdvertisementPlace.GAMES_OVERVIEW: {
              urlToPreload = (currentGamesOverviewAdvertisementAsset as AdvertisementAsset).viewports[viewSize]
                .imageUrl;
              break;
            }
            case AdvertisementPlace.RANKING: {
              urlToPreload = (currentRankingAdvertisementAsset as AdvertisementAsset).viewports[viewSize].imageUrl;
              break;
            }
          }

          return AdvertisementActions.preloadAdvertisementStart({ urlToPreload, placement });
        },
      ),
    );
  });

  onPreloadAdvertisementAsset = createEffect(() => {
    return this.actions.pipe(
      ofType(AdvertisementActions.preloadAdvertisementStart),
      concatLatestFrom(() => this.advertisementFacade.alreadyLoadedAssetImgUrls$),
      concatMap(([{ urlToPreload, placement }, alreadyLoadedAssetImgUrls]) => {
        if (alreadyLoadedAssetImgUrls.has(urlToPreload)) {
          return of(AdvertisementActions.preloadAdvertisementSuccess({ urlToPreload, placement }));
        }

        return this.advertisementService.preloadAdvertisementAsset$(urlToPreload).pipe(
          map(() => AdvertisementActions.preloadAdvertisementSuccess({ urlToPreload, placement })),
          catchError(() => {
            return of(AdvertisementActions.preloadAdvertisementFailure({ urlToPreload, placement }));
          }),
        );
      }),
    );
  });

  onSetClubAdvertisementsStart = createEffect(() => {
    return this.actions.pipe(
      ofType(AdvertisementActions.setClubAdvertisementsStart),
      map(() => AdvertisementActions.initAllAdvertisementAssetsTypes()),
    );
  });

  onInitAllAdvertisementAssetsTypes = createEffect(() => {
    return this.actions.pipe(
      ofType(AdvertisementActions.initAllAdvertisementAssetsTypes),
      concatLatestFrom(() => [
        this.advertisementFacade.currentRankingAdvertisementAsset$,
        this.advertisementFacade.currentInGameAdvertisementAsset$,
        this.advertisementFacade.currentGamesOverviewAdvertisementAsset$,
        this.viewSize$,
      ]),
      filter(
        ([, currentBannerAdvertisementAsset, currentSquareAdvertisementAsset, currentSquareSmallAdvertisementAsset]) =>
          currentBannerAdvertisementAsset !== null &&
          currentSquareAdvertisementAsset !== null &&
          currentSquareSmallAdvertisementAsset !== null,
      ),
      map(
        ([
          ,
          currentBannerAdvertisementAsset,
          currentSquareAdvertisementAsset,
          currentSquareSmallAdvertisementAsset,
          viewSize,
        ]) => {
          return AdvertisementActions.preloadMultiAdvertisementsStart({
            urlsToPreload: [
              (currentBannerAdvertisementAsset as AdvertisementAsset).viewports[viewSize].imageUrl,
              (currentSquareAdvertisementAsset as AdvertisementAsset).viewports[viewSize].imageUrl,
              (currentSquareSmallAdvertisementAsset as AdvertisementAsset).viewports[viewSize].imageUrl,
            ],
          });
        },
      ),
    );
  });

  onPreloadMultiAdvertisements = createEffect(() => {
    return this.actions.pipe(
      ofType(AdvertisementActions.preloadMultiAdvertisementsStart),
      concatLatestFrom(() => this.advertisementFacade.alreadyLoadedAssetImgUrls$),
      concatMap(([{ urlsToPreload }, alreadyLoadedAssetImgUrls]) => {
        const preloadRequests = urlsToPreload
          .filter((url) => !alreadyLoadedAssetImgUrls.has(url))
          .map((urlToPreload) => this.advertisementService.preloadAdvertisementAsset$(urlToPreload));

        if (preloadRequests.length === 0) {
          return of(AdvertisementActions.preloadMultiAdvertisementsSuccess({ urlsToPreload }));
        }

        return forkJoin(preloadRequests).pipe(
          map(() => AdvertisementActions.preloadMultiAdvertisementsSuccess({ urlsToPreload })),
          catchError(() => {
            return of(AdvertisementActions.preloadMultiAdvertisementsFailure({ urlsToPreload }));
          }),
        );
      }),
    );
  });

  onGetUserSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(this.userFacade.getBackendUserSuccess),
      filter(({ user }) => user?.favoriteFootballTeam?.name != null),
      map(({ user }) => {
        return AdvertisementActions.setClubAdvertisementsStart({ clubName: user.favoriteFootballTeam?.name as string });
      }),
    );
  });

  onLogoutFirebaseUserSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(this.userFacade.logoutFirebaseUserSuccess),
      map(() => {
        return AdvertisementActions.setClubAdvertisementsStart({ clubName: null });
      }),
    );
  });

  onInitAdvertisementAssets = createEffect(() => {
    return this.actions.pipe(
      ofType(AdvertisementActions.initAdvertisementAssets),
      map(() => AdvertisementActions.initAllAdvertisementAssetsTypes()),
    );
  });
}
